.Col {
  border: 1px solid blue;
  margin-left: auto !important;
  width: 30% !important;
  max-width: 30% !important;
  margin-top: 3%;
  padding: 0 !important;
  border-radius: 20px;
  overflow: hidden;
}
.Col img {
  width: 100%;
  height: 350px;
}
.Text {
  text-align: center;
  font-size: 30px;
  padding: 2% 0;
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4% 0;
}
.buttons button {
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.delete {
  background-color: red;
  border: 1px solid red;
  color: white;
}
.put {
  background-color: #0066cc;
  color: white;
  border: 1px solid #0066cc;
}
