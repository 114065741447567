.form input {
  width: 100%;
  padding: 13px 10px;
  margin-top: 3%;
  border: 1px solid #0066cc;
  border-radius: 10px;
  outline: none;
}
.form button {
  width: 100%;
  padding: 13px 10px;
  margin-top: 3%;
  border: 1px solid #0066cc;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
}
.postBtn {
  background: linear-gradient(90.3deg, #0066cc 0%, #3685e8 99.74%);
  color: white;
  font-size: 20px;
}
.form select {
  width: 100%;
  padding: 13px 10px;
  margin-top: 3%;
  border: 1px solid #0066cc;
  border-radius: 10px;
  outline: none;
  background-color: transparent;
}
