.Col {
  border: 1px solid blue;
  margin: 2% auto 0 !important;
  width: 20% !important;
  max-width: 20% !important;
  padding: 0 !important;
  border-radius: 20px;
  overflow: hidden;
}
.Col img {
  width: 100%;
  height: 250px;
}
.Col .texts {
  padding: 5%;
}
.texts h3 {
  margin-top: 3% !important;
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4% 0;
}
.buttons button {
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.delete {
  background-color: red;
  border: 1px solid red;
  color: white;
}
.put {
  background-color: #0066cc;
  color: white;
  border: 1px solid #0066cc;
}
.text {
  padding: 5%;
}
