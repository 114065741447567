.header {
  background: linear-gradient(90.3deg, #0066cc 0%, #3685e8 99.74%);
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header h2 {
  margin: 0;
  color: white;
  padding: 0;
}
.header button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: white;
  border: 1px solid white;
  color: #0066cc;
  border-radius: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  font-weight: bold;
}
.header {
  background: linear-gradient(90.3deg, #0066cc 0%, #3685e8 99.74%);
  padding: 30px 20px;
}
.header h2 {
  margin: 0;
  color: white;
  padding: 0;
}
.Wrapper {
  padding: 2%;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4%;
}
.Wrapper button {
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: red;
  border: 1px solid red;
  color: white;
}

.Wrapper table thead tr th {
  text-align: center;
  border-left: 60px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 60px solid transparent;
  font-size: 30px;
}
.Wrapper table tbody tr td {
  font-size: 25px;
}
.dec {
  width: 80%;
  margin: 0 auto;
  overflow: scroll;
  height: 50px;
}
.Wrapper p {
  margin: 0;
  padding: 0;
}
