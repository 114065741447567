.header {
  background: linear-gradient(90.3deg, #0066cc 0%, #3685e8 99.74%);
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header h2 {
  margin: 0;
  color: white;
  padding: 0;
}
.header button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: white;
  border: 1px solid white;
  color: #0066cc;
  border-radius: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  font-weight: bold;
}
