.Wrapper {
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 5%;
}
.buttons button {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #004b79;
  border: 1px solid #004b79;
  border-radius: 10px;
  cursor: pointer;
}
