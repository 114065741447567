.btn-anime {
  margin-top: 30px;
  text-decoration: none;
  text-transform: uppercase;
  padding:10px 30px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  background: transparent;
  border: none;
  position: relative;
  color: #fff;
  cursor: pointer;
}

.btn-anime::before,
.btn-anime::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-anime::before {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #03544c;
  border-radius: 10px;
  box-shadow: 0 10px 30px #07a192;
}
.btn-anime::after {
  bottom: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: #ffffff15;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.151);
  border-radius: 50px;
}

.btn-anime:hover::before {
  bottom: -7px;
  right: -5px;
  width: 110%;
  height: 110%;
}
.btn-anime:hover::after {
  border-radius: 10px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
