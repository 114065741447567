.header {
  background: linear-gradient(90.3deg, #0066cc 0%, #3685e8 99.74%);
  padding: 30px 20px;
}
.header h2 {
  margin: 0;
  color: white;
  padding: 0;
}
.Wrapper {
  padding: 2%;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4%;
}
.Wrapper button {
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: red;
  border: 1px solid red;
  color: white;
}

.Wrapper table thead tr th {
  border-left: 110px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 110px solid transparent;
  font-size: 30px;
  margin: 0 auto;
}
.Wrapper table tbody tr td {
  font-size: 25px;
}
